@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .ring-brand-shadow-xs {
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(0, 64, 249, 0.24);
  }
  .ring-error-shadow {
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(240, 68, 56, 0.24);
  }
}
:root {
  --plyr-color-main: #0c36aa;
  --plyr-control-spacing: 10px;
  --plyr-control-icon-size: 16px;
  --plyr-range-fill-background: white;
  --plyr-range-track-height: 8px;
  --plyr-range-thumb-height: 8px;
  --plyr-range-thumb-shadow: none;
  --plyr-video-control-background-hover: transparent;
}

.root {
  margin: 0 auto;
  font-family: "Inter", sans-serif;
}

body {
  font-family: "Inter", sans-serif;
}

.sb-show-main.sb-main-padded {
  padding: 0px;
}

.rich-text ol {
  @apply list-decimal list-outside;
  margin-left: 1em;
}

.rich-text li {
  margin-bottom: 0.5em;
}

.rich-text li p {
  @apply inline;
}

.rich-text p:not(:last-child),
.rich-text code:not(:last-child),
.rich-text ol:not(:last-child),
.rich-text ul:not(:last-child),
.rich-text pre:not(:last-child),
.rich-text blockquote:not(:last-child) {
  @apply responsive-pb-sm;
}

.rich-text ul {
  @apply list-disc list-outside;
  margin-left: 1em;
}

.rich-text a {
  @apply text-brand-900 stroke-brand-900 underline;
  @apply hover:text-brand-700 hover:stroke-brand-700;
}

.rich-text.container-dark-mode p a {
  @apply text-white;
  @apply hover:text-brand-300;
}

.rich-text h1 {
  @apply text-brand-900 responsive-h1;
}

.rich-text h2 {
  @apply text-brand-900 responsive-h2;
}

.rich-text h3 {
  @apply text-brand-900 responsive-h3;
}

.rich-text h4 {
  @apply text-brand-900 responsive-h4;
}

.rich-text h5 {
  @apply text-brand-900 responsive-h5;
}

.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5 {
  @apply pb-6;
}

.rich-text table {
  @apply w-full table-fixed;
}

.rich-text thead > tr {
  @apply text-xs text-gray-700 capitalize bg-gray-200 font-bold;
}

.rich-text tbody > tr {
  @apply text-sm text-gray-700  font-normal bg-white hover:bg-gray-50;
}

.rich-text th,
.rich-text td {
  @apply text-left px-2 py-3 h-[90px];
}

.rich-text tr {
  @apply bg-white border-b;
}
